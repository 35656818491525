import React from "react";
import "./_styles/App.css";
import Loading from "./_components/Loading";
import DragList from "./_components/DragList";

function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <DragList />;
}

export default App;
