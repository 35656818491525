import React from "react";
import { Activity } from "./types";
import ReactWordcloud from "react-wordcloud";

const words = [
  { text: "unexpected", value: 1 },
  { text: "traumatic", value: 3 },
  { text: "overwhelming", value: 1 },
  { text: "baby blues", value: 1 },
  { text: "shocking", value: 3 },
  { text: "love", value: 1 },
  { text: "exciting", value: 3 },
  { text: "isolating", value: 1 },
  { text: "lonely", value: 3 },
  { text: "foggy", value: 3 },
  { text: "exhausting", value: 9 },
  { text: "hard", value: 3 },
  { text: "easy", value: 1 },
  { text: "prepared", value: 1 },
  { text: "supportive", value: 4 },
  { text: "unprepared", value: 3 },
];

export const WordCloudActivity = ({
  isActive,
  activity,
}: {
  activity: Activity;
  isActive: boolean;
}) => {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [relatableTextShown, setRelatableTextShown] = React.useState(false);
  const [userWord1, setUserWord1] = React.useState("");
  const [userWord2, setUserWord2] = React.useState("");
  const [userWord3, setUserWord3] = React.useState("");

  React.useEffect(() => {
    if (hasSubmitted) {
      setTimeout(() => {
        setRelatableTextShown(true);
      }, 3000);
    }
  }, [hasSubmitted]);
  if (hasSubmitted) {
    return (
      <>
        <div className="bg-white h-full w-full rounded-3xl p-4 text-black shadow flex flex-col">
          <div className="flex-grow">
            <ReactWordcloud
              options={{
                colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd"],
                deterministic: true,
                fontFamily: "Bricolage Grotesque",
                fontSizes: [15, 60],
                padding: 1,
                rotations: 3,
                rotationAngles: [0, 90],
                scale: "linear",
                spiral: "rectangular",
                transitionDuration: 1000,
              }}
              words={[...words]}
            />
          </div>
          <div className="mb-8">
            <h1
              className={`text-2xl font-bold text-center transition-all duration-1000 ${
                relatableTextShown ? "opacity-100" : "opacity-0"
              }`}
            >
              You're in good company
            </h1>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="bg-white h-full w-full rounded-3xl p-4 text-black shadow">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-center">
            What are a few words you would use to describe your postpartum
            experience?
          </h1>
        </div>
        <div className="text-base">
          <input
            className="px-4 py-1 mb-4 rounded-sm border-2 border-tara-purple w-full"
            type="text"
            value={userWord1}
            onChange={(e) => {
              setUserWord1(e.target.value);
            }}
          />
          <input
            className="px-4 py-1 mb-4 rounded-sm border-2 border-tara-purple w-full"
            type="text"
            value={userWord2}
            onChange={(e) => {
              setUserWord2(e.target.value);
            }}
          />
          <input
            className="px-4 py-1 mb-4 rounded-sm border-2 border-tara-purple w-full"
            type="text"
            value={userWord3}
            onChange={(e) => {
              setUserWord3(e.target.value);
            }}
          />
          <button
            className="bg-green-700 text-white rounded-sm px-4 py-2 w-full"
            onClick={() => {
              if (
                words.find(
                  (word) => word.text.toLowerCase() === userWord1.toLowerCase()
                )
              ) {
                words.find(
                  (word) => word.text.toLowerCase() === userWord1.toLowerCase()
                )!.value += 1;
              } else {
                words.push({ text: userWord1.toLowerCase(), value: 1 });
              }
              if (
                words.find(
                  (word) => word.text.toLowerCase() === userWord2.toLowerCase()
                )
              ) {
                words.find(
                  (word) => word.text.toLowerCase() === userWord2.toLowerCase()
                )!.value += 1;
              } else {
                words.push({ text: userWord2.toLowerCase(), value: 1 });
              }
              if (
                words.find(
                  (word) => word.text.toLowerCase() === userWord3.toLowerCase()
                )
              ) {
                words.find(
                  (word) => word.text.toLowerCase() === userWord3.toLowerCase()
                )!.value += 1;
              } else {
                words.push({ text: userWord3.toLowerCase(), value: 1 });
              }
              setHasSubmitted(true);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
};
