import { Video } from "lucide-react";
import React, { useState } from "react";

function VideoUpload() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (selectedFile) {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append("video", selectedFile);

      // Send the file to your backend API
      fetch("/api/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response from your API
          console.log("Upload successful:", data);
        })
        .catch((error) => {
          // Handle any errors during the upload
          console.error("Upload failed:", error);
        });
    }
  };

  return (
    <div>
      <div
        className="bg-white rounded-full p-4 text-black"
        onClick={() => inputRef.current?.click()}
      >
        <Video size={50} />
      </div>
      <input
        className="hidden"
        ref={inputRef}
        type="file"
        accept="video/*"
        onChange={(event) => setSelectedFile(event.target.files![0])}
      />
    </div>
  );
}

export default VideoUpload;
