import { Video, Mic, Pen } from "lucide-react";
import { Activity } from "./types";
import VideoUpload from "../VideoUpload";

export const PromptActivity = ({
  isActive,
  activity,
}: {
  activity: Activity;
  isActive: boolean;
}) => {
  return (
    <>
      <div className="mb-4 text-center">
        <h1 className="text-xl mb-4">Now your turn!</h1>
        <div className="mb-4">
          <h1 className="text-2xl font-bold">{activity.title}</h1>
        </div>
      </div>
      <div className="flex justify-around">
        <VideoUpload />
        <div
          className="bg-white rounded-full p-4 text-black"
          onClick={() => alert("TODO: Scroll to next")}
        >
          <Mic size={50} />
        </div>
        <div
          className="bg-white rounded-full p-4 text-black"
          onClick={() => alert("TODO: Scroll to next")}
        >
          <Pen size={50} />
        </div>
      </div>
    </>
  );
};
