import { Activity } from "./types";

export const PromptSeedActivity = ({
  isActive,
  activity,
}: {
  activity: Activity;
  isActive: boolean;
}) => {
  return (
    <>
      <div className="mb-4 text-center">
        <p className="text-xl mb-4">Ponder for a sec...</p>
        <div className="mb-4">
          <h1 className="text-2xl font-bold">{activity.title}</h1>
        </div>
      </div>
    </>
  );
};
