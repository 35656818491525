import React from "react";
import backHill from "../imgs/back-hill.svg";
import frontHills from "../imgs/front-hills.svg";

export default function Loading() {
  const [pageLoaded, setPageLoaded] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 1);
  }, []);
  return (
    <div className="h-screen w-screen bg-gradient-to-br to-[#3A41C6] from-[#98A5FB] overflow-hidden relative">
      <div className="absolute h-full md:w-full min-w-[1100px] right-1/2 translate-x-1/2">
        <img
          src={backHill}
          alt="back-hill"
          className="bottom-0 right-0 absolute max-w-[80%]"
        />
        <div
          className={`absolute w-full transition-all duration-3000 text-center ease-in-out ${
            pageLoaded ? "bottom-3/4" : "bottom-0"
          }`}
        >
          <h1 className="text-8xl text-white font-bold text-center">Tara</h1>
        </div>
        <div
          className={`text-white text-2xl font-semibold absolute w-full transition-all delay-3000 duration-500 text-center ease-in-out bottom-[63%] md:bottom-2/3 ${
            pageLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <p>
            A space for new moms <br />
            to learn and grow together
          </p>
        </div>
        <img
          src={frontHills}
          alt="front-hills"
          className="bottom-0 right-0 w-full absolute"
        />
      </div>
    </div>
  );
}
