import { Video, Mic, Pen } from "lucide-react";
import { Activity } from "./types";

export const FinalActivity = ({
  isActive,
  activity,
}: {
  activity: Activity;
  isActive: boolean;
}) => {
  return (
    <>
      <div className="mb-4 text-center">
        <h1 className="text-xl mb-4">
          Thanks for embracing vulnerability & sharing
        </h1>
        <div className="mb-4">
          <h1 className="text-2xl font-bold">{activity.title}</h1>
        </div>
      </div>
      <div className="flex justify-between gap-4">
        <button
          className="bg-white rounded-xl p-4 w-24 text-black text-xl"
          onClick={() => alert("TODO: End the demo somehow")}
        >
          No
        </button>
        <button
          className="bg-green-600 border-green-300 border flex-grow rounded-xl p-4 text-white font-bold text-xl"
          onClick={() => alert("TODO: End the demo somehow")}
        >
          Yes
        </button>
      </div>
    </>
  );
};
