import { useRef, useEffect } from "react";
import { Activity } from "./types";
import React from "react";
import { PlayIcon } from "lucide-react";

export const VideoActivity = ({
  isActive,
  activity,
}: {
  activity: Activity;
  isActive: boolean;
}) => {
  const [playing, setPlaying] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current && !videoRef.current.onplay) {
      videoRef.current.onplay = () => setPlaying(true);
      videoRef.current.onpause = () => setPlaying(false);
      setPlaying(!videoRef.current.paused);
    }
    if (isActive) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [isActive]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!videoRef.current) return;
      if (!isActive) return;
      setProgress(videoRef.current.currentTime);
      setDuration(videoRef.current.duration);
    }, 100); // Updates every 100ms

    return () => clearInterval(intervalId);
  }, [isActive]);

  return (
    <div className="bg-white h-full w-full rounded-3xl overflow-hidden text-black flex flex-col shadow relative">
      <video
        id={`video-${activity.id}`}
        className="absolute w-full bottom-0 max-w-xl right-1/2 translate-x-1/2"
        ref={videoRef}
        playsInline
        preload="auto"
        onClick={() => {
          videoRef.current?.paused
            ? videoRef.current?.play()
            : videoRef.current?.pause();
        }}
      >
        <source src={activity.url} type="video/mp4" />
      </video>
      <div className="absolute top-0 left-0 right-0 pl-3 p-2 pb-8 text-white bg-gradient-to-t from-transparent to-gray-600">
        <p className="text-xl font-bold">{activity.title}</p>
        <p className="text-sm ">{activity.author}</p>
      </div>
      {!playing && (
        <div
          className="absolute bottom-1/2 right-1/2 transform translate-x-1/2 translate-y-1/2 rounded-full primary-bg p-4 shadow"
          onClick={() => videoRef.current?.play()}
        >
          <PlayIcon size={100} fill="white" stroke="white" />
        </div>
      )}
      <div className="absolute bottom-6 w-full px-4">
        <div className="relative pt-4 w-full bg-gray-300 rounded-full">
          <div
            className="absolute top-1/2 -translate-y-1/2 left-[2px] h-3 primary-bg rounded-full"
            style={{
              width: `${(progress / duration) * 100}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
